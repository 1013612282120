export function includes<T, U extends T>(arr: readonly U[], elem: T): elem is U {
  const tmp: readonly T[] = arr
  return tmp.includes(elem)
}

export function assertIsDefined<T>(val: T): asserts val is NonNullable<T> {
  if (val === undefined || val === null) {
    // NOTE: 例外を投げるだけだとsystem testでエラーが表示されないケースがあった為console.errorでも出力している
    // eslint-disable-next-line no-console
    console.error(`Expected 'val' to be defined, but received ${val}`)
    throw new Error(`Expected 'val' to be defined, but received ${val}`)
  }
}

export function assertUnreachable(_x: never): never {
  throw new Error("Didn't expect to get here")
}
