<script lang="ts">
import { includes } from '@/src/lib/typeUtils'

const SIZES = ['xs', 'sm', 'md', 'lg'] as const
type Sizes = (typeof SIZES)[number]
</script>

<script lang="ts" setup>
const props = withDefaults(
  defineProps<{
    name: string
    url: string
    size?: Sizes | `${number}px` | `${number}rem`
  }>(),
  {
    size: 'sm',
  },
)

const isPredefinedSize = computed<boolean>(() => includes(SIZES, props.size))
const className = computed(() => (isPredefinedSize.value ? `user-icon--${props.size}` : undefined))
const styles = computed(() => {
  return {
    width: isPredefinedSize.value ? undefined : `${props.size}`,
    height: isPredefinedSize.value ? undefined : `${props.size}`,
  }
})
</script>

<template>
  <img :src="url" :alt="name" :title="name" class="rounded-circle" :class="className" :style="styles" />
</template>

<style lang="scss" scoped>
.user-icon--xs {
  height: 16px;
  width: 16px;
}

.user-icon--sm {
  height: 24px;
  width: 24px;
}

.user-icon--md {
  height: 32px;
  width: 32px;
}

.user-icon--lg {
  height: 40px;
  width: 40px;
}
</style>
