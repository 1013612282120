export interface PossibleTypesResultData {
  possibleTypes: {
    [key: string]: string[]
  }
}
const result: PossibleTypesResultData = {
  possibleTypes: {
    Attachable: ['Comment', 'Issue', 'Message', 'Task'],
    CommentCounter: ['Issue', 'Message', 'Task'],
    Commentable: ['Comment', 'Issue', 'Message', 'Task'],
    CreateBookmarkResult: ['Bookmark', 'ValidationErrors'],
    CreateCommentResult: ['Comment', 'ValidationErrors'],
    CreateIssueResult: ['Issue', 'ValidationErrors'],
    CreateIssuesResult: ['CreateIssuesResultSuccess', 'ValidationErrors'],
    CreateMembershipByEmailResult: ['Membership', 'ValidationErrors'],
    CreateMembershipResult: ['Membership', 'ValidationErrors'],
    CreateMessageResult: ['Message', 'ValidationErrors'],
    CreateMilestoneResult: ['Milestone', 'ValidationErrors'],
    CreateTaskResult: ['Task', 'ValidationErrors'],
    CreateTasksResult: ['CreateTasksResultSuccess', 'ValidationErrors'],
    IssueOrMilestone: ['Issue', 'Milestone'],
    Node: [
      'Attachment',
      'Blob',
      'Bookmark',
      'Comment',
      'Issue',
      'Membership',
      'Message',
      'MessageTemplate',
      'Milestone',
      'Project',
      'Reaction',
      'Task',
      'User',
    ],
    Reactionable: ['Comment', 'Message'],
    TaskOrMilestone: ['Milestone', 'Task'],
    TaskReferrer: ['Comment', 'Message'],
    Ticket: ['Issue', 'Milestone', 'Task'],
    UpdateBookmarkResult: ['Bookmark', 'ValidationErrors'],
    UpdateCommentResult: ['Comment', 'ValidationErrors'],
    UpdateIssueResult: ['Issue', 'ValidationErrors'],
    UpdateMessageResult: ['Message', 'ValidationErrors'],
    UpdateMilestoneResult: ['Milestone', 'ValidationErrors'],
    UpdateTaskResult: ['Task', 'ValidationErrors'],
  },
}
export default result
