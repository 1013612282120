<script lang="ts" setup>
type User = {
  name: string
  imageThumbUrl: string
}

type OnClick = () => void

const props = defineProps<{
  user: User
  content: string
  url: string
  onClick?: OnClick
}>()

const handleClick = () => {
  window.open(props.url)
  props.onClick && props.onClick()
}
</script>

<template>
  <div class="vstack gap-3" role="button" data-test-selector="update-notification" @click="handleClick">
    <div class="d-flex flex-grow-1 align-items-center">
      <UserIcon :url="user.imageThumbUrl" :name="user.name" size="sm"></UserIcon>
      <strong class="ms-2">{{ user.name }}</strong>
    </div>
    <div class="tw-line-clamp-5">{{ content }}</div>
  </div>
</template>
