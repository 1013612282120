import { openToast } from '@sonicgarden/bs-toast-element'
import { createApp } from 'vue'
import UpdateNotification from '@/src/components/UpdateNotification.vue'
import { isBackForwardNavigation } from '@/src/lib/browser'
import type { VueComponentProps } from '@/src/types/vueUtil'

export const notify = (content: string): void => {
  openToast(content, { color: 'white', bgColor: 'primary', delay: 5000, opacity: 75 })
}

export const notifyError = (content: string | HTMLElement): void => {
  openToast(content, { bgColor: 'danger', delay: 20_000 })
}

export const notifyErrors = (errors: readonly string[]): void => {
  const ul = document.createElement('ul')
  ul.classList.add('m-0', 'ps-3')

  for (const error of errors) {
    const li = document.createElement('li')
    li.textContent = error
    ul.append(li)
  }
  notifyError(ul)
}

export const notifySnackbar = (content: string): void => {
  openToast(content, { color: 'white', bgColor: 'dark', delay: 1500, position: 'bottom-center' })
}

const showFlash = (): void => {
  if (isBackForwardNavigation()) return

  const { flash } = window.N2JK.rails

  if (flash.notice) notify(flash.notice)
  if (flash.alert) notifyError(flash.alert)
}

const initNotify = (): void => {
  document.addEventListener('DOMContentLoaded', showFlash)
}

const showUpdateNotification = (
  props: Pick<VueComponentProps<typeof UpdateNotification>, 'user' | 'url' | 'content'>,
): void => {
  const wrapper = document.createElement('div')
  const el = document.createElement('div')
  wrapper.append(el)

  createApp(UpdateNotification, props).mount(el)

  const hide = openToast(wrapper, { color: 'dark', bgColor: 'light', delay: 10_000 })
  wrapper.addEventListener('click', () => hide())
}

export { initNotify, showUpdateNotification }
