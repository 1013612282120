import type { NormalizedCacheObject, Operation } from '@apollo/client/core'
import { ApolloClient, InMemoryCache, ApolloLink, split } from '@apollo/client/core'
import { BatchHttpLink } from '@apollo/client/link/batch-http'
import { setContext } from '@apollo/client/link/context'
import { relayStylePagination } from '@apollo/client/utilities'
import { createConsumer } from '@rails/actioncable'
import { Kind } from 'graphql'
import ActionCableLink from 'graphql-ruby-client/subscriptions/ActionCableLink'
import generatedIntrospection from '@/src/graphql/introspectionResult'
import { csrfToken } from '@/src/lib/rails'
import { createErrorLink } from './apolloCreateErrorLink'

interface GetApolloClient {
  (): ApolloClient<NormalizedCacheObject>
  client?: ApolloClient<NormalizedCacheObject>
}

const hasSubscriptionOperation = ({ query: { definitions } }: Operation): boolean => {
  return definitions.some(
    (definition) => definition.kind === 'OperationDefinition' && definition.operation === 'subscription',
  )
}

export const getApolloClient: GetApolloClient = () => {
  if (getApolloClient.client) {
    return getApolloClient.client
  }

  const cable = createConsumer()

  const httpLink = new BatchHttpLink({
    uri: '/graphql',
    batchMax: 50,
  })

  const authLink = setContext((_, { headers }) => {
    return {
      headers: {
        ...headers,
        'X-CSRF-TOKEN': csrfToken(),
      },
    }
  })

  const cache = new InMemoryCache({
    possibleTypes: generatedIntrospection.possibleTypes,
    // NOTE: レコード削除時の警告抑制
    // SEE: https://www.apollographql.com/docs/react/caching/cache-field-behavior/#merging-non-normalized-objects
    typePolicies: {
      Query: {
        fields: {
          // NOTE: 参照先が存在しない場合はデフォルトだと{}が返ることで型と一致しなくなり予期しない実行時エラーの元となるのでundefinedを返すように設定している
          // SEE: https://www.apollographql.com/docs/react/caching/garbage-collection/#dangling-references
          message(existing, { canRead }) {
            return canRead(existing) ? existing : undefined
          },
          issue(existing, { canRead }) {
            return canRead(existing) ? existing : undefined
          },
          issueOrMilestone(existing, { canRead }) {
            return canRead(existing) ? existing : undefined
          },
          task(existing, { canRead }) {
            return canRead(existing) ? existing : undefined
          },
          taskOrMilestone(existing, { canRead }) {
            return canRead(existing) ? existing : undefined
          },
        },
      },
      Comment: {
        fields: {
          comments: {
            merge: false,
          },
          reactions: {
            merge: false,
          },
          tasks: {
            merge: false,
          },
        },
      },
      Task: {
        fields: {
          comments: {
            merge: false,
          },
          attachments: {
            merge: false,
          },
          labels: {
            merge: false,
          },
          siblings: {
            merge: false,
          },
          referrers: {
            merge: false,
          },
        },
      },
      Issue: {
        fields: {
          comments: {
            merge: false,
          },
          attachments: {
            merge: false,
          },
          labels: {
            merge: false,
          },
          siblings: {
            merge: false,
          },
        },
      },
      Message: {
        fields: {
          comments: {
            merge: false,
          },
          reactions: {
            merge: false,
          },
        },
      },
      Project: {
        fields: {
          tasks: relayStylePagination(),
          issues: relayStylePagination(),
        },
      },
      Channel: {
        fields: {
          messages: relayStylePagination(),
        },
      },
    },
  })

  const bugsnagBreadcrumbLink = new ApolloLink((operation, forward) => {
    return forward(operation).map((result) => {
      const operationNode = operation.query.definitions[0]
      if (operationNode.kind === Kind.OPERATION_DEFINITION) {
        const message = `[GraphQL] ${operationNode.operation} ${operationNode.name?.value}`
        console.debug(message)
        window.bugsnagClient?.leaveBreadcrumb(message)
      }

      return result
    })
  })

  const link = split(
    hasSubscriptionOperation,
    new ActionCableLink({ cable }),
    ApolloLink.from([createErrorLink(), bugsnagBreadcrumbLink, authLink, httpLink]),
  )

  // Create the apollo client
  getApolloClient.client = new ApolloClient({
    link,
    cache,
    defaultOptions: {
      watchQuery: {
        // NOTE: 関連するキャッシュ更新時の自動refetchをデフォルトでは無効化
        // SEE: https://www.apollographql.com/docs/react/data/queries/#nextfetchpolicy
        //      https://www.sonicgarden.world/groups/2208/entries/1704287
        nextFetchPolicy: 'cache-only',
      },
    },
  })

  return getApolloClient.client
}
