<script lang="ts" setup>
import { useCurrentElement, useVModel } from '@vueuse/core'

const props = defineProps<{
  modelValue?: string
  size?: 'sm' | 'lg'
}>()

const emit = defineEmits(['input'])
const modelValue = useVModel(props, 'modelValue', emit, { passive: true, eventName: 'input' })

defineExpose({
  inputRef: useCurrentElement<HTMLInputElement>(),
})

defineOptions({
  inheritAttrs: false,
})
</script>

<template>
  <input v-model="modelValue" v-bind="$attrs" class="form-control" :class="size ? `form-control-${size}` : undefined" />
</template>
