/* eslint-disable */
// Don't edit manually. `rake js:routes` generates this file.
type Value = string | number | (string | number)[];
type Params<Keys extends string> = { [key in Keys]: Value } & Record<string, Value>
function process(route: string, params: Record<string, Value> | undefined, keys: string[]): string {
  if (!params) return route
  var query: string[] = [];
  for (var param in params) if (Object.prototype.hasOwnProperty.call(params, param)) {
    if (keys.indexOf(param) === -1) {
      if (Array.isArray(params[param])) {
        for (var value of params[param] as (string | number)[]) {
          query.push(param + "[]=" + encodeURIComponent(value.toString()));
        }
      } else {
        query.push(param + "=" + encodeURIComponent(params[param].toString()));
      }
    }
  }
  return query.length ? route + "?" + query.join("&") : route;
}

export function newUserSessionPath(params?: Record<string, Value>) { return process('/users/sign_in', params, []); }
export function userSessionPath(params?: Record<string, Value>) { return process('/users/sign_in', params, []); }
export function destroyUserSessionPath(params?: Record<string, Value>) { return process('/users/sign_out', params, []); }
export function newUserPasswordPath(params?: Record<string, Value>) { return process('/users/password/new', params, []); }
export function editUserPasswordPath(params?: Record<string, Value>) { return process('/users/password/edit', params, []); }
export function userPasswordPath(params?: Record<string, Value>) { return process('/users/password', params, []); }
export function newUserConfirmationPath(params?: Record<string, Value>) { return process('/users/confirmation/new', params, []); }
export function userConfirmationPath(params?: Record<string, Value>) { return process('/users/confirmation', params, []); }
export function updateUserConfirmationPath(params?: Record<string, Value>) { return process('/users/confirmation', params, []); }
export function apiTasksPath(params?: Record<string, Value>) { return process('/api/tasks', params, []); }
export function projectApiTokensPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/api_tokens', params, ['projectId']); }
export function newProjectApiTokenPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/api_tokens/new', params, ['projectId']); }
export function editProjectApiTokenPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/api_tokens/' + params.id + '/edit', params, ['projectId','id']); }
export function projectApiTokenPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/api_tokens/' + params.id + '', params, ['projectId','id']); }
export function projectReleasesPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/releases', params, ['projectId']); }
export function dashboardProjectPath(params: Params<'id'>) { return process('/projects/' + params.id + '/dashboard', params, ['id']); }
export function termsOfServiceProjectPath(params: Params<'id'>) { return process('/projects/' + params.id + '/terms_of_service', params, ['id']); }
export function projectArchivePath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/archive', params, ['projectId']); }
export function projectTasksCalendarPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/tasks/calendar', params, ['projectId']); }
export function projectTasksDownloadPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/tasks/download', params, ['projectId']); }
export function projectBookmarksPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/bookmarks', params, ['projectId']); }
export function newProjectBookmarkPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/bookmarks/new', params, ['projectId']); }
export function editProjectBookmarkPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/bookmarks/' + params.id + '/edit', params, ['projectId','id']); }
export function projectBookmarkPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/bookmarks/' + params.id + '', params, ['projectId','id']); }
export function projectChannelArchivePath(params: Params<'projectId' | 'channelId'>) { return process('/projects/' + params.projectId + '/channels/' + params.channelId + '/archive', params, ['projectId','channelId']); }
export function projectChannelsPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/channels', params, ['projectId']); }
export function newProjectChannelPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/channels/new', params, ['projectId']); }
export function editProjectChannelPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/channels/' + params.id + '/edit', params, ['projectId','id']); }
export function projectChannelPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/channels/' + params.id + '', params, ['projectId','id']); }
export function projectTasksPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/tasks', params, ['projectId']); }
export function projectTaskPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/tasks/' + params.id + '', params, ['projectId','id']); }
export function projectIssuesPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/issues', params, ['projectId']); }
export function projectIssuePath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/issues/' + params.id + '', params, ['projectId','id']); }
export function archiveAllProjectMessagesPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/messages/archive_all', params, ['projectId']); }
export function projectMessagesPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/messages', params, ['projectId']); }
export function projectMessagePath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/messages/' + params.id + '', params, ['projectId','id']); }
export function projectConfidentialMessageBlobPath(params: Params<'projectId' | 'confidentialMessageId' | 'id'>) { return process('/projects/' + params.projectId + '/confidential_messages/' + params.confidentialMessageId + '/blobs/' + params.id + '', params, ['projectId','confidentialMessageId','id']); }
export function projectConfidentialMessagesPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/confidential_messages', params, ['projectId']); }
export function newProjectConfidentialMessagePath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/confidential_messages/new', params, ['projectId']); }
export function projectConfidentialMessagePath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/confidential_messages/' + params.id + '', params, ['projectId','id']); }
export function activateProjectMembershipUserPath(params: Params<'projectId' | 'membershipId'>) { return process('/projects/' + params.projectId + '/memberships/' + params.membershipId + '/user/activate', params, ['projectId','membershipId']); }
export function deactivateProjectMembershipUserPath(params: Params<'projectId' | 'membershipId'>) { return process('/projects/' + params.projectId + '/memberships/' + params.membershipId + '/user/deactivate', params, ['projectId','membershipId']); }
export function editProjectMembershipUserPath(params: Params<'projectId' | 'membershipId'>) { return process('/projects/' + params.projectId + '/memberships/' + params.membershipId + '/user/edit', params, ['projectId','membershipId']); }
export function projectMembershipUserPath(params: Params<'projectId' | 'membershipId'>) { return process('/projects/' + params.projectId + '/memberships/' + params.membershipId + '/user', params, ['projectId','membershipId']); }
export function inviteProjectMembershipsPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/memberships/invite', params, ['projectId']); }
export function projectMembershipsPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/memberships', params, ['projectId']); }
export function projectMembershipPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/memberships/' + params.id + '', params, ['projectId','id']); }
export function projectLabelGroupLabelsPath(params: Params<'projectId' | 'labelGroupId'>) { return process('/projects/' + params.projectId + '/label_groups/' + params.labelGroupId + '/labels', params, ['projectId','labelGroupId']); }
export function newProjectLabelGroupLabelPath(params: Params<'projectId' | 'labelGroupId'>) { return process('/projects/' + params.projectId + '/label_groups/' + params.labelGroupId + '/labels/new', params, ['projectId','labelGroupId']); }
export function editProjectLabelGroupLabelPath(params: Params<'projectId' | 'labelGroupId' | 'id'>) { return process('/projects/' + params.projectId + '/label_groups/' + params.labelGroupId + '/labels/' + params.id + '/edit', params, ['projectId','labelGroupId','id']); }
export function projectLabelGroupLabelPath(params: Params<'projectId' | 'labelGroupId' | 'id'>) { return process('/projects/' + params.projectId + '/label_groups/' + params.labelGroupId + '/labels/' + params.id + '', params, ['projectId','labelGroupId','id']); }
export function projectLabelGroupsPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/label_groups', params, ['projectId']); }
export function newProjectLabelGroupPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/label_groups/new', params, ['projectId']); }
export function editProjectLabelGroupPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/label_groups/' + params.id + '/edit', params, ['projectId','id']); }
export function projectLabelGroupPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/label_groups/' + params.id + '', params, ['projectId','id']); }
export function projectEmergencyContactsPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/emergency_contacts', params, ['projectId']); }
export function projectEmergencyContactPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/emergency_contacts/' + params.id + '', params, ['projectId','id']); }
export function projectAttachmentAttachablePath(params: Params<'projectId' | 'attachmentId'>) { return process('/projects/' + params.projectId + '/attachments/' + params.attachmentId + '/attachable', params, ['projectId','attachmentId']); }
export function projectAttachmentPdfPreviewPath(params: Params<'projectId' | 'attachmentId'>) { return process('/projects/' + params.projectId + '/attachments/' + params.attachmentId + '/pdf_preview', params, ['projectId','attachmentId']); }
export function projectAttachmentPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/attachments/' + params.id + '', params, ['projectId','id']); }
export function projectImagesPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/images', params, ['projectId']); }
export function projectFilesPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/files', params, ['projectId']); }
export function projectImportantDocumentsPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/important_documents', params, ['projectId']); }
export function projectImportantDocumentPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/important_documents/' + params.id + '', params, ['projectId','id']); }
export function projectMessageTemplatesPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/message_templates', params, ['projectId']); }
export function newProjectMessageTemplatePath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/message_templates/new', params, ['projectId']); }
export function editProjectMessageTemplatePath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/message_templates/' + params.id + '/edit', params, ['projectId','id']); }
export function projectMessageTemplatePath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/message_templates/' + params.id + '', params, ['projectId','id']); }
export function projectWebhooksPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/webhooks', params, ['projectId']); }
export function newProjectWebhookPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/webhooks/new', params, ['projectId']); }
export function editProjectWebhookPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/webhooks/' + params.id + '/edit', params, ['projectId','id']); }
export function projectWebhookPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/webhooks/' + params.id + '', params, ['projectId','id']); }
export function projectMeetingSchedulesPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/meeting_schedules', params, ['projectId']); }
export function newProjectMeetingSchedulePath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/meeting_schedules/new', params, ['projectId']); }
export function editProjectMeetingSchedulePath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/meeting_schedules/' + params.id + '/edit', params, ['projectId','id']); }
export function projectMeetingSchedulePath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/meeting_schedules/' + params.id + '', params, ['projectId','id']); }
export function projectPublicMeetingSchedulesPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/public_meeting_schedules', params, ['projectId']); }
export function projectRemindersPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/reminders', params, ['projectId']); }
export function newProjectReminderPath(params: Params<'projectId'>) { return process('/projects/' + params.projectId + '/reminders/new', params, ['projectId']); }
export function editProjectReminderPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/reminders/' + params.id + '/edit', params, ['projectId','id']); }
export function projectReminderPath(params: Params<'projectId' | 'id'>) { return process('/projects/' + params.projectId + '/reminders/' + params.id + '', params, ['projectId','id']); }
export function projectsPath(params?: Record<string, Value>) { return process('/projects', params, []); }
export function newProjectPath(params?: Record<string, Value>) { return process('/projects/new', params, []); }
export function editProjectPath(params: Params<'id'>) { return process('/projects/' + params.id + '/edit', params, ['id']); }
export function projectPath(params: Params<'id'>) { return process('/projects/' + params.id + '', params, ['id']); }
export function editUserNoticePath(params?: Record<string, Value>) { return process('/user/notice/edit', params, []); }
export function userNoticePath(params?: Record<string, Value>) { return process('/user/notice', params, []); }
export function editUserPath(params?: Record<string, Value>) { return process('/user/edit', params, []); }
export function userPath(params?: Record<string, Value>) { return process('/user', params, []); }
export function publicUserImagePath(params: Params<'id'>) { return process('/public_user_images/' + params.id + '', params, ['id']); }
export function passkeysPath(params?: Record<string, Value>) { return process('/passkeys', params, []); }
export function editPasskeyPath(params: Params<'id'>) { return process('/passkeys/' + params.id + '/edit', params, ['id']); }
export function passkeyPath(params: Params<'id'>) { return process('/passkeys/' + params.id + '', params, ['id']); }
export function adminsRootPath(params?: Record<string, Value>) { return process('/admins', params, []); }
export function archiveAdminsProjectPath(params: Params<'id'>) { return process('/admins/projects/' + params.id + '/archive', params, ['id']); }
export function unarchiveAdminsProjectPath(params: Params<'id'>) { return process('/admins/projects/' + params.id + '/unarchive', params, ['id']); }
export function adminsProjectMembershipsPath(params: Params<'projectId'>) { return process('/admins/projects/' + params.projectId + '/memberships', params, ['projectId']); }
export function adminsProjectMembershipPath(params: Params<'projectId' | 'id'>) { return process('/admins/projects/' + params.projectId + '/memberships/' + params.id + '', params, ['projectId','id']); }
export function adminsProjectsPath(params?: Record<string, Value>) { return process('/admins/projects', params, []); }
export function editAdminsProjectPath(params: Params<'id'>) { return process('/admins/projects/' + params.id + '/edit', params, ['id']); }
export function adminsProjectPath(params: Params<'id'>) { return process('/admins/projects/' + params.id + '', params, ['id']); }
export function activateAdminsUserPath(params: Params<'id'>) { return process('/admins/users/' + params.id + '/activate', params, ['id']); }
export function deactivateAdminsUserPath(params: Params<'id'>) { return process('/admins/users/' + params.id + '/deactivate', params, ['id']); }
export function adminsUsersPath(params?: Record<string, Value>) { return process('/admins/users', params, []); }
export function editAdminsUserPath(params: Params<'id'>) { return process('/admins/users/' + params.id + '/edit', params, ['id']); }
export function adminsUserPath(params: Params<'id'>) { return process('/admins/users/' + params.id + '', params, ['id']); }
export function adminsBlazerPath(params?: Record<string, Value>) { return process('/admins/blazer', params, []); }
export function adminsRailsPgExtrasWebPath(params?: Record<string, Value>) { return process('/admins/pg_extras', params, []); }
export function adminsGoodJobPath(params?: Record<string, Value>) { return process('/admins/good_job', params, []); }
